import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import LinkUi from '@material-ui/core/Link';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
//import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CloseIcon from '@material-ui/icons/Close';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ReorderIcon from '@material-ui/icons/Reorder';
import AppsIcon from '@material-ui/icons/Apps';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';
import AuthService from "../Services/AuthService";
// import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import IconButton from '@material-ui/core/IconButton';
//import MoreVertIcon from '@material-ui/icons/MoreVert';
import MiPerfil from '../Perfil/Perfil'
import Lottie from 'react-lottie-player'
import loading from "../Lottie/33637-ondas-sonoras.json"
import Pagination from "react-js-pagination";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        width: 250,
        height: 205
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    MailU: {
        fontSize: 12,
        marginBottom:12
    },
    PhoneU: {
        fontSize: 12,
        marginBottom:12
    },
    NameU: {
        marginBottom: 12,
        fontSize: 13,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        // height: "50px",
        textTransform: "capitalize",
        overflow: "hidden"
    },
});
const GreenCheckbox = withStyles({
    root: {
        color: "#808080",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const BlueCheckbox = withStyles({
    root: {
        color: "#00AC46",
        '&$checked': {
            color: "#00AC46",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
/**
 * Componente de usuarios internos dentro del directorio
 * @param {*} props Datos del usuario logeado
 * @returns Regresa la vista de los usuarios internos
 */
export default function Internos(props) {

    let history = useHistory();
    const classesCard = useStyles();
    const [currentUser] = useState(AuthService.getCurrentUser());
    //const [userEstructuraAll, setUserEstructuraAll] = useState([]);
    //const [usrsIntr, setUsrsIntr] = useState([]); // Este array sera para mostrar los usuarios que sean elegidos
    const [verLista, setVerLista] = useState(false);
    const [bucaInterno, setbucaInterno] = useState(false);
    const [usrsAll, setUsrsAll] = useState([]);
    const [usrsAdmns, setUsrsAdmns] = useState([]);
    const [usrsRM, setUsrsRM] = useState([]);
    const [usrsAM, setUsrsAM] = useState([]);
    const [usrsGrnt, setUsrsGrnt] = useState([]);
    const [usrsLdrs, setUsrsLdrs] = useState([]);
    const [usrsPrsn, setUsrsPrsn] = useState([]);
    const [usrs, setUsrs] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [userSlcc, setUserSlcc] = useState({});
    const [completed] = useState(true);
    const todosPerPage = 20;
    const [ activePage, setCurrentPage ] = useState( 1 );
    const [ currentTodos, setCurrentTodos] = useState( [] );
    const [ textoFiltrar, setTextoFiltrar] = useState("");
    const [ sortDown, setSortDown] = useState(false);

    useEffect(() => {
        setearUsuarios( JSON.parse(JSON.stringify(props.usrsIntr)) )
    }, []);
    useEffect(() => {
        //console.log("Vamos a setear users " + usrs.length)
        if(usrs.length>0){
            const indexLastTodo  = activePage * todosPerPage;
            const indexFirstTodo = indexLastTodo - todosPerPage;
            //console.log(indexFirstTodo + " - " + indexLastTodo)
            let newUsrs = JSON.parse(JSON.stringify( usrs ))
            setCurrentTodos( newUsrs.slice(indexFirstTodo, indexLastTodo) )
        } else {
            setearUsuarios( JSON.parse(JSON.stringify(props.usrsIntr)) )
        }
    }, [activePage]);

    // Logic for displaying current todos
    const indexOfLastTodo  = activePage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    //setCurrentTodos( JSON.parse(JSON.stringify( usrs.slice( indexOfFirstTodo, indexOfLastTodo ) )) )
    /**
     * Función para separar a los usuarios internos de acuerdo a su rol
     * @param {*} usuarios Lista de usuarios internos
     */
    const setearUsuarios=(usuarios)=>{
        setUsrsAll(usuarios);
        setUsrsAdmns(usuarios.filter((el,i)=>el.rol[0].value===0));
        setUsrsRM(usuarios.filter((el,i)=>el.rol[0].value===9));
        setUsrsAM(usuarios.filter((el,i)=>el.rol[0].value===8));
        setUsrsGrnt(usuarios.filter((el,i)=>el.rol[0].value===5));
        setUsrsLdrs(usuarios.filter((el,i)=>el.rol[0].value===3));
        setUsrsPrsn(usuarios.filter((el,i)=>el.rol[0].value===1));
        /* 
        let usrsPage = []
        if(usuarios.filter((el,i)=>el.rol[0].value===0).length>0){
            usrsPage = (usuarios.filter((el,i)=>el.rol[0].value===0))
        } else
        if(usuarios.filter((el,i)=>el.rol[0].value===9).length>0){
            usrsPage = (usuarios.filter((el,i)=>el.rol[0].value===9))
        } else
        if(usuarios.filter((el,i)=>el.rol[0].value===8).length>0){
            usrsPage = (usuarios.filter((el,i)=>el.rol[0].value===8))
        } else
        if(usuarios.filter((el,i)=>el.rol[0].value===5).length>0){
            usrsPage = (usuarios.filter((el,i)=>el.rol[0].value===5))
        } else
        if(usuarios.filter((el,i)=>el.rol[0].value===3).length>0){
            usrsPage = (usuarios.filter((el,i)=>el.rol[0].value===3))
        }
        */
        setUsrs(usuarios);
        let newUsrs = JSON.parse(JSON.stringify(usuarios.slice(indexOfFirstTodo, indexOfLastTodo)))
        setCurrentTodos( newUsrs )
    }
    /**
     * Función para ver en formato de listado
     * @param {*} verLsta Bandera para indicar si se desea activar vista de listado
     */
    const handleVerLista = (verLsta) =>{
        setVerLista(verLsta)
    }
    /**
     * Función para setar variable para buscar un usuario
     * @param {*} bucaInterno Parámetro con el usuario a buscar
     */
    const handlebucaInterno = (bucaInterno) =>{
        setbucaInterno(bucaInterno)
    }
    /**
     * Función para seleccionar todos los usuarios internos
     * @param {*} event Control de valor del elemento
     */
    const handleCheckAll = (event) => {
        setCheckAll(event.target.checked)
        usrs.map((el,i)=>{
            return el.checked=event.target.checked
        })
        let newUsrs = JSON.parse(JSON.stringify(usrs.slice(indexOfFirstTodo, indexOfLastTodo)))
        setCurrentTodos( newUsrs )
    }
    /**
     * Función para seleccionar uno por uno entre el listado de usuarios
     * @param {*} event Control de valor del elemento
     * @param {*} id ID del usuario que se esta seleccionando
     */
    const handleCheckOne = (event, id) => {
        //console.log(id+ " vamos a checar uno por uno ..." + event.target.checked)
        setCheckAll(false)
        let nU = []
        usrs.forEach((el,i)=>{
            if(el.id===id){
                el.checked = event.target.checked
            }
            nU.push(el)
        })
        setUsrs(nU)
        let newUsrs = JSON.parse(JSON.stringify(nU.slice(indexOfFirstTodo, indexOfLastTodo)))
        setCurrentTodos( newUsrs )
    }
    /**
     * Función para invitar a un evento a los usuarios seleccionados
     * @param {*} path Parámetro para cambiar a la sección del calendario
     * @param {*} titleToolbar Parámetro para colocar nombre de la nueva sección 
     */
    const handleInvitarEvento = (path, titleToolbar) => {
        let usrsInvt = usrs.filter((el)=> el.checked === true);
        let usuarios = btoa(JSON.stringify(usrsInvt));
        localStorage.setItem("usersEvent", usuarios);
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
    }
    /**
     * Función para filtrat por roles
     * @param {*} val Valor del ID rol
     */
    const handleChangeUser = (val) => {
        setCurrentPage(1)
        setTextoFiltrar("")
        let usrsPage = []
        switch(val){
            case 0:
                usrsPage = (usrsAdmns)
                break;
            case 9:
                usrsPage = (usrsRM)
                break;
            case 8:
                usrsPage = (usrsAM)
                break;
            case 5:
                usrsPage = (usrsGrnt)
                break;
            case 3:
                usrsPage = (usrsLdrs)
                break;
            case 1:
                usrsPage = (usrsPrsn)
                break;
            default:
                usrsPage = (usrs)
                break;
        }
        setUsrs( usrsPage );
        //handlePageChange(1)
        let newUsrs = JSON.parse(JSON.stringify(usrsPage.slice(indexOfFirstTodo, indexOfLastTodo)))
        setCurrentTodos( newUsrs )
    }
    /**
     * Función para abrir modal
     * @param {*} user Datos del usuario interno 
     */
    const handleClickOpenModal = (user) => {
        console.log(user);
        setUserSlcc(user);
        setOpenModal(true);
    };
    /**
     * Función para cerrar modal 
     * @param {*} ev 
     */
    const handleCloseModal = (ev) =>{
        setUserSlcc({});
        setOpenModal(false);
    };
    /**
     * Función para ordenar de acuerdo a filtro enviado
     * @param {*} fltr Parámetro con el filtro con el cual se oredenara el listado
     */
    const handleClickSortDownUp = (fltr) => {
        //console.log("Vamos a ordenar ascendente..." + sortDown)
        setSortDown(!sortDown)
        let usrsFtlr = JSON.parse(JSON.stringify(usrs))
        let newsUsrs =
            fltr === 'nombre' ?
                ( usrsFtlr.sort((usrA, usrB) =>
                    (usrB.nombre.toLowerCase() < usrA.nombre.toLowerCase()) ? -1 : (usrB.nombre.toLowerCase() > usrA.nombre.toLowerCase()) ? 1 : 0
                ) ):
            fltr === 'idu'?
                ( usrsFtlr.sort((usrA, usrB) =>
                    (usrB.idu.toLowerCase() < usrA.idu.toLowerCase()) ? -1 : (usrB.idu.toLowerCase() > usrA.idu.toLowerCase()) ? 1 : 0
                )):
            (
                usrsFtlr.sort((usrA, usrB) =>
                (usrB.oficina.name.toLowerCase() < usrA.oficina.name.toLowerCase()) ? -1 : (usrB.oficina.name.toLowerCase() > usrA.oficina.name.toLowerCase()) ? 1 : 0
            ));

        if(sortDown){
            newsUsrs = newsUsrs.reverse()
        }
        //console.log(newsUsrs)
        setUsrs(newsUsrs)
        setCurrentPage( 1 )
        setCurrentTodos( newsUsrs.slice(0, 20) )
    }

    const renderTodos = currentTodos.map( ( el, index ) => {
        if (verLista) {
            return <li key={ index } style={{listStyle: "none"}}>
                <div className="row">
                    {
                        index === 0 &&
                        <div className="col l12 s12" style={{marginTop: "20px"}}>
                            <div className="col l1 s1"> <b>&nbsp;</b> </div>
                            <div className="col l3 s5">
                                <div className="">
                                    <b>Nombre</b>
                                    <IconButton  className="infoContactBulk desktopshow" onClick={()=>handleClickSortDownUp("nombre")}>
                                        { sortDown ?
                                            <ArrowDropDownIcon fontSize="large"/> :
                                            <ArrowDropUpIcon fontSize="large"/>
                                        }
                                    </IconButton>
                                </div>
                            </div>
                            <div className="col l2 s2">
                                <div className="">
                                    <b>IDU</b>
                                    <IconButton  className="infoContactBulk desktopshow" onClick={()=>handleClickSortDownUp("idu")}>
                                        { sortDown ?
                                            <ArrowDropDownIcon fontSize="large"/> :
                                            <ArrowDropUpIcon fontSize="large"/>
                                        }
                                    </IconButton>
                                </div>
                            </div>
                            <div className="col l2 desktopshow">
                                <div className="">
                                    <b>Gerencia</b>
                                    <IconButton  className="infoContactBulk" onClick={()=>handleClickSortDownUp("gerencia")}>
                                        { sortDown ?
                                            <ArrowDropDownIcon fontSize="large"/> :
                                            <ArrowDropUpIcon fontSize="large"/>
                                        }
                                    </IconButton>
                                </div>
                            </div>
                            <div className="col l2 desktopshow"> <b>Correo</b> </div>
                            <div className="col l3 s4" style={{textAlign: "center"}}>
                                <span className={el.rol[0].value!==0 ?"col l4 s2": "col l6 s2"}></span>
                                <FormGroup row className={el.rol[0].value!==0 ?"col l4 s2": "col l6 s2"}>
                                    <FormControlLabel
                                        control={
                                            <GreenCheckbox className="nopadding" checked={checkAll}
                                                onChange={handleCheckAll} name="all" />}
                                        label=""
                                    />
                                </FormGroup>
                                {
                                    el.rol[0].value !== 0 &&
                                        <span className="desktopshow">Perfil</span>
                                }
                            </div>
                        </div>
                    }
                    <div className="col l12 s12" style={ index%2===0 ? { paddingTop:"9px", backgroundColor: "white" }:{ } }>
                        <div className="col l1 s1 ContactListViewCont">
                            <Brightness1Icon className={"labelCategariaInterno"+el.rol[0].value}/>
                        </div>
                        <div className="col l3 s5 ContactListViewCont"> {el.nombre + ' ' + (el.apellido==null? '' : el.apellido)} </div>
                        <div className="col l2 s2 ContactListViewCont nopadding" style={{textAlign: "center"}}> {el.idu} </div>
                        <div className="col l2 desktopshow ContactListViewCont"> {el.oficina.name} </div>
                        <div className="col l2 desktopshow ContactListViewCont"> {el.correo} </div>
                        <div className="col l2 s4 ContactListViewCont nopadding" style={{textAlign: "center"}}>
                            <IconButton className={el.rol[0].value!==0 ?"col ": "col nopadding"} onClick={()=>handleClickOpenModal(el)} >
                                <VisibilityIcon color="disabled"/>
                            </IconButton>
                            <FormGroup row className={el.rol[0].value!==0 ?"col ": "col nopadding"}>
                                <FormControlLabel style={{margin: "auto"}} 
                                    control={
                                        <GreenCheckbox checked={el.checked} style={{paddingTop: "0px", margin: "0"}}
                                            onChange={(ev)=>handleCheckOne(ev, el.id)} name={"user"+el.id} />}
                                    label=""
                                />
                            </FormGroup>
                            {
                                el.rol[0].value!==0 && currentUser.rol[0].value===0 &&
                                <Tooltip className="col nopadding" title={"Ver Perfil"}>
                                    <IconButton onClick={() => changePerfil(el.idu)}>
                                        <SupervisedUserCircle fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </div>
                </div>
            </li>
        } else {
            return <li key={ index } style={{listStyle: "none"}}>
                <div className="col s12 l3" key={index}>
                    <Card className={classesCard.root + ' cardContact'}>
                        <CardContent style={{textAlign: "center", padding:"5px"}}>
                            <FormGroup className="checkContactBulk"
                                style={{width: "1em", display: "inline-block"}}>
                                <FormControlLabel
                                    control={
                                        <GreenCheckbox checked={el.checked}
                                            onChange={(ev)=>handleCheckOne(ev, el.id)} name={"user"+el.id} />}
                                    label=""
                                />
                            </FormGroup>
                            {/* TODO: El color esta fijo en theme_themomix.css */}
                            <FiberManualRecordIcon className={"typeContactBulk labelCategariaInterno"+el.rol[0].value} fontSize="large" />
                            <Typography className={classesCard.NameU} >
                                {el.nombre + ' ' + (el.apellido==null? '' : el.apellido)}
                            </Typography>
                            <Typography className={classesCard.PhoneU} color="textSecondary" gutterBottom>
                                {el.telefono}
                            </Typography>
                            <Typography className={classesCard.MailU} color="textSecondary" gutterBottom>
                                {el.correo}
                            </Typography>
                            <CardActions className={el.rol[0].value!==0 ? "infoContactBulkAdmin":"infoContactBulk"}>
                                {
                                    el.rol[0].value!==0 && currentUser.rol[0].value===0 &&
                                        <Tooltip title={"Ver Perfil"}>
                                            <IconButton aria-label="Ver Perfil" className="infoContactBulk"
                                                        onClick={() => changePerfil(el.idu)}>
                                                <img src="/assets/img/icons/perfil_gray_23.png" className="iconPerfil" alt="Ver Perfil"/>
                                            </IconButton>
                                        </Tooltip>
                                }
                                <IconButton  className="infoContactBulk" onClick={() => handleClickOpenModal(el)}>
                                <img src="/assets/img/icons/informacion_gray_23.png" className="iconInfo" alt="Ver Información"/>
                                </IconButton>
                            </CardActions>
                        </CardContent>
                    </Card>
                </div>
            </li>
        }
    })
    /**
     * Función para cuando se cambia de pagina 
     * @param {*} pageNumber Número de página
     */
    const handlePageChange = ( pageNumber ) => {
        //console.log( `active page is ${ pageNumber }` );
        setCurrentPage( pageNumber )
        const indexLastTodo  = pageNumber * todosPerPage;
        const indexFirstTodo = indexLastTodo - todosPerPage;
        //console.log(indexFirstTodo + " - " + indexLastTodo)
        let newUsrs = JSON.parse(JSON.stringify( usrs ))
        setCurrentTodos( newUsrs.slice(indexFirstTodo, indexLastTodo) )
    };
    
    const onchangeFiltrar = (ev) => {
        setTextoFiltrar(ev.target.value)
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            filtrar(e);
        }
    }
    /**
     * Funcion para realizar el filtrado entre los usuarios internos
     * @param {*} e 
     */
    const filtrar = (e) => {
        //console.log("Vamos a filtrar por el texto: " + textoFiltrar)
        let usrsFtlr = JSON.parse(JSON.stringify(usrsAll))
        //console.log(usrsFtlr)
        let newsUsrs = usrsFtlr.filter(usr =>
            (
                usr.nombre.toLowerCase().indexOf(textoFiltrar.toLowerCase())>=0 ||
                (usr.apellido!==null && usr.apellido.length>0 && usr.apellido.toLowerCase().indexOf(textoFiltrar.toLowerCase())>=0) ||
                usr.correo.toLowerCase().indexOf(textoFiltrar.toLowerCase())>=0 ||
                usr.oficina.name.toLowerCase().indexOf(textoFiltrar.toLowerCase())>=0
            )
        )
        //console.log(newsUsrs)
        setUsrs(newsUsrs)
        setCurrentPage( 1 )
        setCurrentTodos( newsUsrs.slice(0, 20) )
    }
    /**
     * Función para poder ver el perfil de otro usuario, solo activo para administradores
     * @param {*} idu IDU del nuevo usaurio interno al que se quiere ver su perfil
     */
    const changePerfil = (idu) => {
        console.log("Cambiar user admin por el seleccionado " + idu)
        let gql = `{
            userByIdu(idu:"${idu}"){
                _id: id
                apellido
                correo
                created_user
                email
                estructura {
                  id_user
                  idu
                  nombre
                  apellido
                  correo
                  telefono
                  rol {
                    value
                    text
                  }
                }
                id_user
                id_user_type_catalog
                idu
                inactivo
                nombre
                privileges
                profile_route
                rol {
                  value
                  text
                }
                status
                sucursal {
                  value
                  name
                }
                telefono
                updatePassword
            }
        }`
        AuthService.consult(gql).then(resp=>{
            console.log(resp.data.data.userByIdu)
            const arrUserSlcc = resp.data.data.userByIdu
            if(arrUserSlcc.length>0){
                localStorage.setItem("userAdmin", JSON.stringify(AuthService.getCurrentUser()))
                let userSlcc = {...arrUserSlcc[0], token: localStorage.getItem('id_token')}
                localStorage.setItem("user", JSON.stringify(userSlcc))
                window.location.reload()
            }
        })
    }

    return (
        <div>
        {
            !completed ?
            <Lottie
                loop
                animationData={loading}
                play
                style={{ width: 450, height: 450, display: "block", margin: "auto" }}
            />
            :
            <div>
                {bucaInterno &&
                <div className="row ">
                        <div className="col l3 desktopshow">
                        </div>
                        <div className="col l3 s9" >
                            <input type="text" value={textoFiltrar} onKeyDown={_handleKeyDown}
                                onChange={(ev)=>onchangeFiltrar(ev)} placeholder="Buscar por usuario o por gerencia..." className="searchExtInput"/>
                        </div>
                        <div className="col l1 s2">
                            <Button onClick={(ev)=>filtrar(ev)}
                                    style={{padding:"5px", backgroundColor: "#00AC46",
                                        borderRadius:"10px", color: "white"}}>
                                <SearchIcon style={{color: "white"}}/>
                            </Button>
                        </div>
                        <div className="col l5 s12">
                            <span className="searchExtInputInfo">{usrs.length + " usuarios encontrados"}</span>
                        </div>
                </div>
                }
                <div className="row UserFilterTypeDiv">
                    <div className="col l10 s12 UserFilterTypeCont" style={{marginTop: '7px'}}>
                        {
                            usrsAdmns.length>0 ?
                                <div className="col l2 s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                                    onClick={()=>handleChangeUser(0)}>
                                    Administrador <span className="labelInterno0">{usrsAdmns.length}</span>
                                </div>
                                :
                                <></>
                        }
                        {
                            usrsRM.length>0 ?
                                <div className="col l2 s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                                    onClick={()=>handleChangeUser(9)}>
                                    Directora Ventas <span className="labelInterno9">{usrsRM.length}</span>
                                </div>
                                :
                                <></>
                        }
                        {
                            usrsAM.length>0 ?
                                <div className="col l2 s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                                    onClick={()=>handleChangeUser(8)}>
                                    Area M <span className="labelInterno8">{usrsAM.length}</span>
                                </div>
                                :
                                <></>
                        }
                        {
                            usrsGrnt.length>0 ?
                                <div className="col l2 s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                                    onClick={()=>handleChangeUser(5)}>
                                    Gerente <span className="labelInterno5">{usrsGrnt.length}</span>
                                </div>
                                :
                                <></>
                        }
                        {
                            usrsLdrs.length>0 ?
                                <div className="col l2 s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                                    onClick={()=>handleChangeUser(3)}>
                                    Líder <span className="labelInterno3">{usrsLdrs.length}</span>
                                </div>
                                :
                                <></>
                        }
                        {
                            usrsPrsn.length>0 ?
                                <div className="col l2 s6 UserFilterType" style={{textAlign: "center", cursor: "pointer"}}
                                    onClick={()=>handleChangeUser(1)}>
                                    Presentador <span className="labelInterno1">{usrsPrsn.length}</span>
                                </div>
                                :
                                <></>
                        }

                    </div>
                    <div className="col l2 s12" style={{textAlign: "center", marginTop: "10px"}}>
                        <div className="row" style={{marginBottom:"5px"}}>
                            <div className="col l4 s4">
                                <SearchIcon fontSize="large" style={bucaInterno ?
                                    {color: "#00AC46", cursor: 'pointer'}:
                                    {color: "#0000008A", cursor: 'pointer'}}
                                            onClick={()=>handlebucaInterno(true)}/>
                            </div>
                            <div className="col l4 s4">
                                <ReorderIcon color="disabled" fontSize="large" style={verLista ?
                                    {color: "#00AC46", cursor: 'pointer'}:
                                    {color: "#0000008A", cursor: 'pointer'}} onClick={()=>handleVerLista(true)}/>
                            </div>
                            <div className="col l4 s4">
                                <AppsIcon fontSize="large" style={verLista ?
                                    {color: "#0000008A", cursor: 'pointer'}:
                                    {color: "#00AC46", cursor: 'pointer'}} onClick={()=>handleVerLista(false)}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div>
                    <div className="row contactContainers" style={{overflow:"scroll"}}>
                        { renderTodos }
                    </div>
                    <div className=" row pagination" style={{textAlign: "center"}}>
                        <Pagination
                            activePage={ activePage }
                            itemsCountPerPage={ 20 }
                            totalItemsCount={ usrs.length }
                            pageRangeDisplayed={ 5 }
                            onChange={ handlePageChange }
                        />
                    </div>
                </div>
                <div className="controlsAllInvite" style={{marginBottom:"30px"}}>
                {
                    usrs.length > 0 &&
                    <div className="row ">
                        {!verLista &&
                        <FormGroup className="selectboxAllCnt" style={{float: "right"}}>
                            <FormControlLabel
                                label="Seleccionar Todos"
                                control={
                                    <BlueCheckbox checked={checkAll} onChange={handleCheckAll} name="all" />
                                }
                                style={{color: "#00AC46"}}
                            />
                        </FormGroup>
                        }
                    </div>
                }
                {
                    usrs.length>0 &&
                    <div className="row">
                        <LinkUi color="inherit" href="/calendario?add=true"
                                onClick={()=>handleInvitarEvento('/calendario?add=true', 'Calendario?add=true') }>
                            <Button className="selectboxAllCnt"
                                style={{width: "200px", padding:"10px", backgroundColor: "#00AC46",
                                    borderRadius:"10px", color: "white"}}>
                                <img src="/assets/img/icons/calendar_white_23.png" alt="Calendario" style={{mixBlendMode: "screen", width: "20px", marginRight: "10px"}}/>
                                Invitar a evento
                            </Button>
                        </LinkUi>
                    </div>
                }
                </div>
                {/* Mostrar dialogo de perfil para este usuario */}
                <Dialog open = {openModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title" onClose={handleCloseModal}>
                    {handleCloseModal ? (
                            <IconButton style={{padding: "0"}} className="closeButtonX" aria-label="close" onClick={handleCloseModal}>
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        {/* Mostrar el perfil */}
                        <MiPerfil user={userSlcc} editar={false}/>
                    </DialogContent>
                </Dialog>
            </div>
        }
        </div>
    );
}