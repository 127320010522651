/* eslint-disable no-mixed-operators */
import React, { useEffect }  from 'react';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {css} from "glamor";
//import AuthService from '../AuthService'
import AuthService from "../Services/AuthService";
import Collections from "../Utils/collections";
import { useHistory } from 'react-router-dom'
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
//import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
//import HomeIcon from '@material-ui/icons/Home';
import InputBase from '@material-ui/core/InputBase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
// import Autocomplete from "@material-ui/lab/Autocomplete";


//To Do OCT23: Start NavBar Modal Test---------------------------------

import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

//End of NavBar Modal test--------------------------------------------

import Badge from '@material-ui/core/Badge';
//import Link from '@material-ui/core/Link';
// user login, logout
import MenuIcon from '@material-ui/icons/Menu';
//import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
//Busqueda
//import SearchIcon from '@material-ui/icons/Search';


import Popover from '@material-ui/core/Popover';


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Search from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import EventIcon from '@material-ui/icons/Event';
//import NotificationsIcon from '@mui/icons-material/Notifications';
//import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';


/*
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import StarIcon from '@material-ui/icons/Star';
*/
// import SettingsIcon from '@material-ui/icons/Settings';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';


import Box from '@material-ui/core/Box';
import LinkUi from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import '../menu-ui-custom.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: '0px',
            //width: '123px',
            //height: '123px',
        },
    },
}));

function Copyright() {
    return (
        <div>
            <Typography variant="body2" color="textSecondary" align="center">
                <LinkUi color="inherit" href="/">
                    VORWERK
                </LinkUi>{' '}
                {'© '}
                {new Date().getFullYear()}<br/>
                {' Todos los derechos Reservados'}<br/>
                {'v'+Collections.CurrentAppVersion}
            </Typography>
        </div>
    );
}

export default function NavBar (props) {

    let history = useHistory();
    const classesA = useStyles();

    // const Auth = AuthService()
    const [classes] = React.useState(props.classes);
    const [socket] = React.useState(props.socket);
    const [open, setOpen] = React.useState(true);
    const [openNtfc, setOpenNtfc] = React.useState(false);
    const [OpenNalerts, setOpenNalerts] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [titleToolbar] = React.useState(
        window.localStorage.getItem("titleToolbar")?
            window.localStorage.getItem("titleToolbar").replace("?add=true",""):
            window.localStorage.getItem("titleToolbar")
    );
    const [evntsNtfc, setEvntsNtfc] = React.useState([]);
    const [user] = React.useState(props.datauser);
    const [foto] = React.useState(typeof(props.datauser.profile_route)!=='undefined'?
        props.datauser.profile_route: "");

    const [AllNtf, setAllNtf] = React.useState([]);
    const [ActNtf, setActNtf] = React.useState([]);
    const [ActNtfNumDsp, setActNtfNumDsp] = React.useState(0);
    const [notificationsModal, setnotificationsModal] = React.useState(false);
    //const [CambioPresentadorData, setCambioPresentadorData] = React.useState([]);
    const [CPinfClientName, setCPinfClientName] = React.useState('');
    const [CPinfFinDate, setCPinfFinDate] = React.useState('');
    const [CPinfStatus, setCPinfStatus] = React.useState('');
    const [CPinfIdCliente, setCPinfIdCliente] = React.useState('');
    //const [CPinfnotificationsID ,setCPinfnotificationsID] = React.useState('');
    //const [usuarios, setUsuarios] = React.useState([]);

    const openAuth = Boolean(anchorEl);

    const alerts = (msg) =>{
        toast(msg, {
            className: css({
                background: "#00AC46",
                color: "white"
            }),
            progressClassName: css({
                background: "#FFF"
            })
        });
    };

    useEffect(() => {

        consultaEventos();
        SetNotifications();
        //console.log("--> " + user.nombre);
        socket.on("requestNtfcUser_" + user.idu, data => {
            console.log(data);
            if(data.correoInvitado===user.correo){
                alerts(data.msg)
                consultaEventos();
            }
        });
    }, []);

    //To Do SEP23: Add notifications querry after this function.
    const SetNotifications = () => {
        AuthService.GetActiveNotifications(user.idu)
            .then(result=>{
                if(result.data.success){
                    console.log('--------------->Adding active notifications:'+result.data.data);
                    setAllNtf(result.data.data);
                    const NtfsAct= result.data.data.filter((el,i)=>el.notificationStatus!==3);
                    setActNtf(NtfsAct);
                    //-------
                    const NtfsCount= result.data.data.filter((el,i)=>el.notificationStatus===1);
                    setActNtfNumDsp(NtfsCount);
                } else {
                    console.log('--------------->result.success was false!!!!!!!!!!!!!!!');
                }
                
            })
            .catch(error=>{
                console.log(error);
                if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                    AuthService.logout();
                }
            });
    }

    const consultaEventos = () => {
        AuthService.consult(`query{
              listaNtfcInvt(iduInvitado: "${user.idu}"){ id tipo titulo fecha modalidad1 modalidad2 notas direccion 
              start end status idTipo listaInvitados { idu nombre correo segmento esExtr sendMail asistire} 
              seRepite seRepiteHasta iduPresentador correoPresentador nombrePresentador frecuencia, created_event }
            }`
        )
            .then(result=>{
                let prprEvnts = result.data.data.listaNtfcInvt;
                let evntNtfc = [];
                prprEvnts.forEach(evnt => {
                    //console.log(evnt);
                    let arrInvt = evnt.listaInvitados ? evnt.listaInvitados: [];
                    let arrInvtAsst = arrInvt.filter((invt)=>invt.asistire===0 && invt.idu === user.idu);
                    if(arrInvtAsst.length>0){
                        evntNtfc.push(evnt)
                    }
                });
                setEvntsNtfc( evntNtfc );
                /*
                let evnts = [];
                // Vamos a ir por el nombre del creador del evento
                prprEvnts.forEach(evnt=>{
                    // Vamos a agregar el campo del nombre de la persona que creo el evento
                    Object.assign(evnt, {"nombrePresentador": ""});
                    let arrInvt = evnt.listaInvitados.filter(invt=>invt.correo === user.correo && invt.asistire===0)
                    //console.log(evnt.listaInvitados)
                    //console.log(user.idu)
                    if(arrInvt.length>0){
                        //console.log(evnt.iduPresentador);
                        AuthService.consult(`query{
                          userByIdu(idu: "${evnt.correoPresentador}"){ id idu nombre apellido}
                        }`)
                            .then(result=>{
                                //console.log(result.data.data.userByIdu)
                                if(result.data.data.userByIdu.length>0){
                                    evnt.nombrePresentador = result.data.data.userByIdu[0].nombre;
                                    evnts.push(evnt)
                                  //  console.log(evnts)

                                    setEvntsNtfc( JSON.parse(JSON.stringify(evnts)) );
                                }
                            })
                            .catch(error=>{console.log(error);});
                    }
                })
                */

            })
            .catch(error=>{
                console.log(error);
                if(error.response && error.response.status===401 && error.response.data.message==="Invalid Token"){
                    AuthService.logout();
                }
            });
    }

    const logout = async () =>{
        AuthService.logout();
        localStorage.setItem("titleToolbar", "");
        window.location.replace('/');
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDrawerOpenClose = () => {
        if(open === true){
            setOpen(false);
            window.localStorage.setItem("openMenu", false);
        }else{
            setOpen(true);
            window.localStorage.setItem("openMenu", true);
        }
    };
    // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const handleClick= (path, titleToolbar) => {
        window.localStorage.setItem('titleToolbar', titleToolbar)
        history.push(path);
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            findEvents(e);
        }
    }

    const findEvents = (e) => {
        //console.log("Vamos a buscar los eventos de tipo: " + e.target.value)
        const item = {
            value: e.target.value,
            expiry: new Date().getTime() + 10000
        };
        localStorage.setItem("findEvent", JSON.stringify(item));
        localStorage.setItem('titleToolbar', 'Eventos')
        history.push('/listadoEventos');
        window.location.reload();
    }

    const [anchorNot, setAnchorNot] = React.useState(null);
    
    const handleNalert = (event) => {
        setOpenNalerts(true);
        setAnchorNot(anchorNot ? null : event.currentTarget);
    };
    const handleCloseNalert = () => {
        setOpenNalerts(false);
        setAnchorNot(null);
    };

    const handleNotify = (event) => {
        setOpenNtfc(true);
        setAnchorNot(anchorNot ? null : event.currentTarget);
    };
    const handleCloseNotify = () => {
        setOpenNtfc(false);
        setAnchorNot(null);
    };

    const handleClosenotificationsModal = () => {
        setnotificationsModal(false);
    }

    const openPop = Boolean(anchorNot);
    const idPop = openPop ? 'simple-popper' : undefined;

    const handleAsistire = (ntfc, asistire) => {
        console.log("El usuario logeado asistira a este evento... " + asistire);
        //console.log(ntfc);
        //Vamos a buscar usuario logeado en la lista de invitados y modificarlo
        ntfc.listaInvitados.map(invt=>{
            if(invt.idu===user.idu){
                invt.asistire=asistire;
                return invt;
            } else {
                return invt;
            }
        });
        console.log(ntfc);
        //Vamos a actualizar este listado de invitados

        let prepareListInvit = "[";
        ntfc.listaInvitados.forEach((invt, i)=>{
            prepareListInvit += '{idu: "'+ invt.idu + '", nombre: "'+ invt.nombre + '", correo: "' +
                invt.correo+'", segmento: "'+invt.segmento+'", esExtr: '+invt.esExtr+', sendMail: '+
                invt.sendMail+', asistire: '+invt.asistire+'}';
        });
        prepareListInvit += "]";

        //Vamos a guardar las lista de invitados
        let gqlUpd = `mutation{
                        updateInvitadosEventMail(
                            id: "${ntfc.id}"
                            listaInvitados: ${prepareListInvit.replace(/}{/g, "},{")},
                        ){
                            id
                            listaInvitados {
                                idu
                                nombre
                                correo
                                segmento
                                esExtr
                                sendMail
                                asistire
                            }
                        }
                    }`;
        console.log(gqlUpd);
        AuthService.consult(gqlUpd)
            .then(resp=>{
                console.log(resp.data.data.updateInvitadosEventMail)
                //alert("chin...");
                window.location.reload()
            })
            .catch(error=>console.log(error));

    }

    const handleVerNotificacion = (ev) => {

        setOpenNalerts(false);

        AuthService.UpdateNotificationStatus(ev.uniqueID, 2)
            .then(result=>{
                if(result.data.success){
                    console.log('--------------->Notification Status Updated');
                }   
            });

        if(ev.notificationProcess==='NetSuiteRecomendados'){
            setTimeout(()=>{
                localStorage.setItem("showReferenciado", ev.notificationsID);
                localStorage.setItem('titleToolbar', 'Directorio')
                history.push('/directorio');
                window.location.reload();
            }, 1000)
        } else if (ev.notificationProcess==='NuevoPresentador'){
            AuthService.CambioPresentadorQrry(ev.notificationsID)
                .then((resp) =>
                {
                    if(resp.data.success){
                        setTimeout(()=>{
                            localStorage.setItem("showReferenciado", resp.data.data.IdCliente);
                            localStorage.setItem('titleToolbar', 'Directorio')
                            history.push('/directorio');
                            window.location.reload();
                        }, 1000)
                    }
                })
                .catch((err) =>
                {
                    console.log('Error in CambioPresentadorQrry process. Error:'+err);
                });
        } else if (ev.notificationProcess==='CambioPorBaja'){
            setTimeout(()=>{
                localStorage.setItem("showReferenciado", ev.notificationsID);
                localStorage.setItem('titleToolbar', 'Directorio')
                history.push('/directorio');
                window.location.reload();
            }, 1000)
        } else if(ev.notificationProcess==='CambioPresentador'){
            AuthService.CambioPresentadorQrry(ev.notificationsID)
                .then((resp) =>
                {
                    if(resp.data.success){
                        setCPinfFinDate(resp.data.DateFinTXT);
                        setCPinfStatus(resp.data.data.EstatusSolicitud);
                        setCPinfIdCliente(resp.data.data.IdCliente);
                        //setCPinfnotificationsID(resp.data.data.notificationsID);
                        console.log('----->ID de cliente para useState:'+resp.data.data.IdCliente);
                        console.log('----->ID de cliente para ser consultado:'+CPinfIdCliente);
                        AuthService.getReferenciadoName(resp.data.data.IdCliente)
                            .then((resp) =>
                            {
                                console.log('------------->Respuesta correcta desde getReferenciadoName.');
                                console.log('------------>Nombre del referenciado recibido: '+resp.data.ExternoName);
                                setCPinfClientName(resp.data.ExternoName);
                            })
                            .catch((err) =>
                            {
                                //To Do OCT23: Add error handler for this case.
                                console.log('------------->Error en respuesta desde getReferenciadoName.');
                            });
                        setnotificationsModal(true);
                    } else {
                        console.log('--------------->RESPUESTA NO!!! CORRECTA DE CambioPresentadorQrry');
                    }
                })
                .catch((err) =>
                {
                    console.log('Error in CambioPresentadorQrry process. Error:'+err);
                });
        }
        
        /*
        console.log("evento: ")
        console.log(ev);
        localStorage.setItem("findEvent", JSON.stringify({id:ev.id, value: ev.tipo}));
        localStorage.setItem('titleToolbar', 'Eventos')
        history.push('/directorio');
        window.location.reload();
        */
    }

    
    const handleArchivarNotificacion = (ev) => {

        setOpenNalerts(false);

        AuthService.UpdateNotificationStatus(ev.uniqueID, 3)
            .then(result=>{
                if(result.data.success){
                    console.log('--------------->Notification Status Updated');
                }
            });
            setTimeout(()=>{
                window.location.reload();
            }, 1000)
    }

    const handleVerCambioPresentador =  () => {
        setnotificationsModal(false);
        setTimeout(()=>{
            localStorage.setItem("showReferenciado", CPinfIdCliente);
            localStorage.setItem('titleToolbar', 'Directorio')
            history.push('/directorio');
            window.location.reload();
        }, 1000)
    }

    const handleVerEvento = (ev) => {
        console.log("evento: ")
        console.log(ev);
        localStorage.setItem("findEvent", JSON.stringify({id:ev.id, value: ev.tipo}));
        localStorage.setItem('titleToolbar', 'Eventos')
        history.push('/listadoEventos');
        window.location.reload();
    }

    const changeAdmin = () => {
        if( localStorage.getItem("userAdmin") ){
            localStorage.setItem("user", localStorage.getItem("userAdmin"))
            localStorage.removeItem("userAdmin")
            window.location.reload()
        }
    }

    const [value, setValue] = React.useState('recents');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <div>
                <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            aria-label="open drawer"
                            onClick={handleDrawerOpenClose}
                            className={'desktopshow ' + clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" noWrap className={classes.title}>
                            { titleToolbar }
                        </Typography>

                        {/* Busqueda */}
                        {// eslint-disable-next-line
                            <div className={'desktopshow ' + classes.search} >
                                <div className={classes.searchIcon} style={{ background: '#F2F1EF',borderRadius: '25px 0 0 25px'}}>
                                    {/* <SearchIcon /> */}
                                    <img src="/assets/img/icons/search_23.png" className="iconSideMenu" alt="Directorio"/>
                                </div>
                                <InputBase
                                    placeholder="Buscar eventos…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    className="inputSearch"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onKeyDown={_handleKeyDown}
                                    autoComplete="searchesNav"
                                />
                                <datalist id="searchesNav">
                                    <option value="Demo Thermomix"></option>
                                    <option value="Entrega Thermomix"></option>
                                    <option value="Contactar"></option>
                                    <option value="Seguimiento"></option>
                                    <option value="Evento Personal"></option>
                                    <option value="Junta General"></option>
                                    <option value="Clases de Cocina"></option>
                                    <option value="Workshop Interno"></option>
                                    <option value="Junta de Líderes de Equipo"></option>
                                    <option value="Junta de Ventas"></option>
                                    <option value="Taller de Conocimiento de Producto"></option>
                                    <option value="Junta de Lanzamiento de Promociones"></option>
                                    <option value="Junta de Gerentes"></option>
                                    <option value="Cumbre de Líderes"></option>
                                    <option value="Kick Off"></option>
                                </datalist>
                            </div>
                        }
                        <div className="mobileshow logoNavMobile">
                            <img src="/assets/img/thermomix_header_2023.png" className="logo_head_sm" alt="thermomix_header"/>
                        </div>

                        {/* Mis Invitaciones */}
                        <IconButton className="notifyNav" onClick={handleNotify} variant="contained" aria-describedby={idPop}>
                            <Badge badgeContent={evntsNtfc? evntsNtfc.length: 0} color="secondary">
                                <img src="/assets/img/icons/invites_23_fat.png" className="iconInvites" alt="Invitaciones"/>
                            </Badge>
                        </IconButton>

                        {/* Notificaciones */}
                        <IconButton className="notifyNav" onClick={handleNalert} variant="contained" aria-describedby={idPop}>
                            <Badge badgeContent={ActNtfNumDsp? ActNtfNumDsp.length: 0} className='BadgesNotifications'>
                                <NotificationsNoneIcon
                                    fontSize="large"
                                    className="bellNotify"
                                />
                            </Badge>
                        </IconButton>

                        

                        <Popover
                            id={idPop}
                            open={openNtfc}
                            anchorEl={anchorNot}
                            onClose={handleCloseNotify}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}

                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            className="notifyCont"
                        >
                            <div>
                                <p className="titleNot">Mis Invitaciones</p>
                            </div>
                            {
                                evntsNtfc && evntsNtfc.map((ntfc,i)=>
                                    <div className="notifyElement newYes" key={i}>
                                        <div className="row nomargin">
                                            <div className="col desktopshow ">
                                                <div className="calendarNotIcon">
                                                    <EventIcon style={{color: "white"}}/>
                                                </div>
                                            </div>
                                            <div className="col nopadding">
                                                <Typography className="notifyText">
                                                    <strong>{ntfc.nombrePresentador}</strong> te ha invitado a
                                                    <strong>
                                                        {' ' + (ntfc.titulo.length>0 ? ntfc.titulo: ntfc.tipo)}
                                                    </strong>
                                                </Typography>
                                                <Typography className="notifyTextMin">
                                                    <strong>
                                                        Para el día {new Date(ntfc.created_date).toLocaleDateString()} a las
                                                        {new Date(ntfc.created_date).toLocaleTimeString()}
                                                    </strong>
                                                </Typography>

                                                <div className="row nomargin">
                                                    <div className="col l4 s5 yesAttendBtn">
                                                        <IconButton onClick={()=>handleVerEvento(ntfc)}>
                                                            <Search style={{color: "gray"}}/>
                                                            <span style={{color: "black"}}>Detalles</span>
                                                        </IconButton>
                                                    </div>
                                                    <div className="col l4 s5 yesAttendBtn">
                                                        <IconButton onClick={()=>handleAsistire(ntfc, 1)}>
                                                            <CheckCircleIcon style={{color: "green"}}/>
                                                            <span>Asistiré</span>
                                                        </IconButton>
                                                    </div>
                                                    <div className="col l4 s5 noAttendBtn">
                                                        <IconButton onClick={()=>handleAsistire(ntfc, 2)}>
                                                            <CancelIcon style={{color: "red"}}/>
                                                            <span>No asistiré</span>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </Popover>

                        <Popover
                            id={idPop}
                            open={OpenNalerts}
                            anchorEl={anchorNot}
                            onClose={handleCloseNalert}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}

                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            className="notifyCont"
                        >
                            <div>
                                <p className="titleNot">Mis Notificaciones</p>
                            </div>
                            {
                                ActNtf && ActNtf.map((ListNtfc,i)=>
                                    <div className={ListNtfc.notificationStatus ===1? "notifyElement newYes" : "notifyElement newNot"} key={i}>
                                        <div className="row nomargin">
                                            <div className="col desktopshow ">
                                                <div className={ListNtfc.notificationStatus !==1? "calendarNotIconOff" : "calendarNotIcon"}>
                                                    <NotificationsNoneIcon style={{color: "white"}}/>
                                                </div>
                                            </div>
                                            <div className="col nopadding">
                                                <Typography className="notifyText">
                                                    <strong>{ListNtfc.notificationTXT}</strong>
                                                </Typography>
                                                <Typography className={ListNtfc.notificationStatus===1?"notifyTextMin":"notifyTextMinOff"}>
                                                    <strong>
                                                    {ListNtfc.notificationSubTXT}
                                                    </strong>
                                                </Typography>

                                                <div className="row nomargin">
                                                    <div className="col l4 s5 yesAttendBtn">
                                                        <IconButton onClick={()=>handleVerNotificacion(ListNtfc)}>
                                                            <Search style={{color: "gray"}}/>
                                                            <span style={{color: "black"}}>Detalles</span>
                                                        </IconButton>
                                                    </div>
                                                    {ListNtfc.notificationStatus!==3 && (
                                                    <React.Fragment>
                                                        <div className="col l4 s5 yesAttendBtn">
                                                        <IconButton onClick={()=>handleArchivarNotificacion(ListNtfc, 1)}>
                                                            <CheckCircleIcon style={{color: "green"}}/>
                                                            <span>Archivar</span>
                                                        </IconButton>
                                                        </div>
                                                    </React.Fragment>)}
                                                    {ListNtfc.notificationStatus===3 && (
                                                    <React.Fragment>
                                                        <div className="col l4 s5 yesAttendBtn">
                                                        <IconButton onClick={()=>console.log('ok')}>
                                                            <CheckCircleIcon style={{color: "gray"}}/>
                                                            <span style={{color: "gray"}}>Archivada</span>
                                                        </IconButton>
                                                        </div>
                                                    </React.Fragment>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                <div className="notifyElement newYes">
                                        <div className="row nomargin">
                                            <div className="col nopadding">
                                                <Typography className="notifyText">
                                                    <Link
                                                    onClick={() => {
                                                        console.info("I'm a button.");
                                                        setActNtf(AllNtf);
                                                      }}
                                                    className="notifyTextLnk">
                                                    <strong>Ver notificaciones archivadas</strong>
                                                    </Link>
                                                </Typography>
                                            </div>
                                        </div>
                                </div>
                            }

                        </Popover>

                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                className="profileNav"
                            >
                                {/* <PermIdentityOutlinedIcon
                                color="disabled"
                                fontSize="large"
                                /> */}
                                {
                                    foto && typeof(foto) !== 'undefined' && foto.length>0 ?
                                        <div className={classesA.root}>
                                            <Avatar alt="fotoperfil" src={Collections.imageProfilePath + foto} />
                                        </div>
                                        /*<img className="iconSideMenu" src={Collections.imageProfilePath + foto} alt="fotoperfil"/>*/
                                        :
                                        <img src="/assets/img/icons/profile_23.png" className="iconPerfilHeader" alt="Perfil"/>
                                }
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={openAuth}
                                onClose={handleClose}>
                                {
                                    (user.rol[0].value === 1 || user.rol[0].value === 3 || user.rol[0].value === 5) &&
                                    <LinkUi color="inherit" href="/"
                                            onClick={()=>handleClick('/misObjetivos', 'Mis Metas') }>
                                        <MenuItem >Mis Metas</MenuItem>
                                    </LinkUi>
                                }
                                <LinkUi color="inherit" href="/"
                                        onClick={()=>handleClick('/miPerfil', 'Mi Perfil') }>
                                    <MenuItem >Perfil</MenuItem>
                                </LinkUi>
                                <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>

                            </Menu>
                        </div>
                        <div className="MuiGrid-root MuiGrid-item desktopshow HeaderProfileText">
                            <p className="MuiTypography-root Sidebar__SidebarFooterText-ljtos8-18 cnNlxk MuiTypography-body2">
                                {user.nombre}
                            </p>
                            <span className="MuiTypography-root Sidebar__SidebarFooterSubText-ljtos8-19 gETRTp MuiTypography-caption">
                                {
                                    user.id_user_type_catalog > 0 || (user.rol && user.rol.length > 0) ?
                                        (user.rol[0].value === 9 ? 'Directora de Ventas' : user.rol[0].text) :
                                        'Administrador'
                                }
                            </span>
                        </div>


                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                    className={'desktopshow ' + classes.sideMenus}
                >
                    <div className={classes.toolbarIcon}>
                        <div className={classes.LogoHolder}>
                            {open && <img src="/assets/img/thermomix_header_2023.png" style={{width: "170px"}} alt=""/> }
                        </div>
                    </div>
                    <List>
                        <div>
                            {
                                localStorage.getItem("userAdmin") &&
                                <LinkUi color="inherit" target="_blank" onClick={()=>changeAdmin()}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Tooltip title="Mi Perfil" placement="top">
                                                {/* <OpenInNewIcon /> */}
                                                <img src="/assets/img/icons/regresar_23.png" className="iconSideMenu" alt="Regresar a Perfil de Administrador"/>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Mi Perfil" />
                                    </ListItem>
                                </LinkUi>
                            }

                            {
                                open ?
                                    <LinkUi color="inherit" href="/calendario"
                                            onClick={()=>handleClick('/calendario', 'Calendario?add=true') }>
                                        <ListItem button style={{backgroundColor: "#00AC46", marginLeft: "20px", borderRadius: "15px",
                                            marginTop:"10px", marginBottom: "10px", width: "190px", height: "50px"}}>
                                            <ListItemIcon style={{minWidth: "25px", marginLeft: "8px"}}>
                                                <Tooltip title="Agregar evento" placement="top">
                                                <img src="/assets/img/icons/agregar_white_23.png" className="iconAgregar" alt="Agregar"/>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary="Agregar evento" className='ButtonAddEvent'/>
                                        </ListItem>
                                    </LinkUi>
                                    :
                                    <LinkUi color="inherit" href="/calendario?add=true"
                                            onClick={()=>handleClick('/calendario', 'Calendario?add=true') }>
                                        <ListItem button style={{backgroundColor: "#00AC46", borderRadius: "15px", width: "50px",
                                            marginLeft: "10px", marginTop:"10px", marginBottom: "10px"}}>
                                            <ListItemIcon style={{minWidth: "25px", marginLeft: "-3px"}}>
                                                <Tooltip title="Agregar evento" placement="top">
                                                    <AddIcon style={{backgroundColor: "#00AC46", color: "#FFF"}}/>
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary="" style={{color: "#FFF"}}/>
                                        </ListItem>
                                    </LinkUi>
                            }

                            <LinkUi color="inherit" href="/" onClick={()=>handleClick('/inicio', 'Inicio') }>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Tooltip title="Inicio" placement="top">
                                            {/* <HomeIcon fontSize="large"/> */}
                                            <img src="/assets/img/icons/home_23.png" className="iconSideMenu" alt="Inicio"/>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary="Inicio" />
                                </ListItem>
                            </LinkUi>
                            <LinkUi color="inherit" href="/directorio" onClick={()=>handleClick('/directorio', 'Directorio') }>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Tooltip title="Directorio" placement="top">
                                            <img src="/assets/img/icons/contacts_23.png" className="iconSideMenu" alt="Directorio"/>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary="Directorio" />
                                </ListItem>
                            </LinkUi>
                            <LinkUi color="inherit" href="/calendario" onClick={()=>handleClick('/calendario', 'Calendario') }>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Tooltip title="Calendario" placement="top">
                                            {/* <DateRangeIcon fontSize="large"/> */}
                                            <img src="/assets/img/icons/calendar_23.png" className="iconSideMenu" alt="Calendario"/>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary="Calendario" />
                                </ListItem>
                            </LinkUi>
                            <LinkUi color="inherit" href="/recursos" onClick={()=>handleClick('/recursos', 'Recursos') }>
                                <ListItem button>
                                    <ListItemIcon>
                                        <Tooltip title="Recursos" placement="top">
                                            {/* <BurstModeIcon fontSize="large"/> */}
                                            <img src="/assets/img/icons/ribbon_23.png" className="iconSideMenu" alt="Recursos"/>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary="Recursos" />
                                </ListItem>
                            </LinkUi>
                            {/* <ListItem button>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText primary="Mis Recursos" />
                            </ListItem> */}


                            <LinkUi color="inherit" href="https://www.mithermomix.mx/" target="_blank" >
                                <ListItem button>
                                    <ListItemIcon>
                                        <Tooltip title="mithermomix.mx" placement="top">
                                            {/* <OpenInNewIcon /> */}
                                            <img src="/assets/img/icons/mithermomix_23.png" className="iconSideMenu" alt="Mi Thermomix"/>
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary="mithermomix.mx" />
                                </ListItem>
                            </LinkUi>

                        </div>
                    </List>
                    <Divider />
                    <List>
                        <div>
                            {
                            user.id_user_type_catalog > 0 || (user.rol && user.rol.length > 0) && 
                            
                                <LinkUi color="inherit" href="/ajustes" onClick={()=>handleClick('/ajustes', 'Ajustes') }>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Tooltip title="Ajustes" placement="top">
                                                {
                                                    //<SettingsIcon />
                                                }
                                                <img src="/assets/img/icons/gear_23.png" className="iconSideMenu" alt="Directorio"/>
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary="Ajustes" />
                                    </ListItem>
                                </LinkUi>
                            }
                            {
                                open &&
                                <Box pt={4}>
                                    <Copyright />
                                </Box>
                            }

                        </div>
                        
                    </List>
                </Drawer>
            </div>
            <div className="mobileMenuApp mobileshow">
                {
                    localStorage.getItem("userAdmin") &&
                    <div className='backtouserAdminMobile'>
                        <div style={{margin: "auto"}} >
                            <LinkUi color="inherit" target="_blank" onClick={()=>changeAdmin()}>
                                <img src="/assets/img/icons/regresar_23.png" className="iconSideMenu" alt="Regresar a Perfil de Administrador"/>
                                <span>Regresar a Perfil de Administrador</span>
                            </LinkUi>
                        </div>
                    </div>
                }
                <BottomNavigation value={value} onChange={handleChange}>
                    <LinkUi color="inherit" href="/" onClick={()=>handleClick('/inicio', 'Inicio') }>
                        <BottomNavigationAction label="Inicio" value="inicio" icon={<img src="/assets/img/icons/home_23.png" className="iconSideMenu" alt="Inicio"/>} />
                    </LinkUi>
                    
                    <LinkUi color="inherit" href="/directorio" onClick={()=>handleClick('/directorio', 'Directorio') }>
                        <BottomNavigationAction label="Directorio" value="directorio" icon={<img src="/assets/img/icons/contacts_23.png" className="iconSideMenu" alt="Directorio"/>} />
                    </LinkUi>
                    
                    <LinkUi color="inherit" href="/calendario" onClick={()=>handleClick('/calendario', 'Calendario?add=true') } style={{backgroundColor: "#00AC46", color: "#FFF", borderRadius: "50% 50% 0 0" }}>
                        <BottomNavigationAction label="Directorio" value="directorio" icon={<AddIcon style={{backgroundColor: "#00AC46", color: "#FFF", fontSize: "30px"}}/>} />
                    </LinkUi>

                    <LinkUi color="inherit" href="/calendario" onClick={()=>handleClick('/calendario', 'Calendario') }>
                        <BottomNavigationAction label="Calendario" value="calendario" icon={<img src="/assets/img/icons/calendar_23.png" className="iconSideMenu" alt="Calendario"/>} />
                    </LinkUi>

                    <LinkUi color="inherit" href="/recursos" onClick={()=>handleClick('/recursos', 'Recursos') }>
                        <BottomNavigationAction label="Recursos" value="Recursos" icon={<img src="/assets/img/icons/ribbon_23.png" className="iconSideMenu" alt="Recursos"/>} />
                    </LinkUi>
                    
                </BottomNavigation>
            </div>
            <Dialog open = {notificationsModal} onClose={handleClosenotificationsModal} aria-labelledby="form-dialog-title">
                    <DialogTitle className="titleNtfcAddEvent" id="form-dialog-title" onClose={handleClosenotificationsModal}>
    
                        <MuiDialogTitle disableTypography >
                            <div className='ModalTitleContainer'>
                                <div className='iconAlertaContainer'>
                                    <img src="/assets/img/icons/alerta_yellow_23.png" className="iconAlertaBig" alt="Aviso"/>
                                </div>
                                <span className="TitleModalContact">
                                    Solicitud de cambio de presentador
                                </span>
                            </div>
                            
                            {handleClosenotificationsModal ? (
                                <IconButton style={{padding: "0"}} className="closeButtonX" aria-label="close" onClick={handleClosenotificationsModal}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            ) : null}
                        </MuiDialogTitle>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        {CPinfStatus==='3' && (
                            <React.Fragment>
                                <Typography className="ModalH1txt">
                                    Tu cliente {CPinfClientName} solicitó un cambio de Presentador por medio de la plataforma Recomienda y Gana con Thermomix®.
                                </Typography>
                                <Typography className="ModalH2txt">
                                    Puedes contactarle para que cancele su solicitud y permanezca como cliente tuyo, para seguir recibiendo sus recomendaciones.
                                </Typography>
                                <Typography className="ModalH2txt">
                                    Si estás conforme con su cambio, no es necesario que realices ninguna acción.
                                </Typography>
                                <Typography className="ModalH1txt">
                                    El cambio se realizará el día: {CPinfFinDate}.
                                </Typography>
                            </React.Fragment>
                        )}
                        {CPinfStatus==='5' && (
                            <React.Fragment>
                                <Typography className="ModalH1txt">
                                    Tu cliente {CPinfClientName} canceló su solicitud de cambio de Presentador por medio de la plataforma Recomienda y Gana con Thermomix®.
                                </Typography>
                                <Typography className="ModalH2txt">
                                    Seguirás recibiendo sus recomendaciones en tu Agenda Digital.
                                </Typography>
                                <Typography className="ModalH2txt">
                                    No es necesario que realices ninguna acción.
                                </Typography>
                            </React.Fragment>
                        )}
                        {CPinfStatus==='6' && (
                            <React.Fragment>
                            <Typography className="ModalH1txt">
                                Tu cliente {CPinfClientName} solicitó un cambio de Presentador por medio de la plataforma Recomienda y Gana con Thermomix®.
                            </Typography>
                            <Typography className="ModalH1txt">
                                El cambio fué realizado el día: {CPinfFinDate}.
                            </Typography>
                            <Typography className="ModalH2txt">
                                Ya no verás su contacto en tu Agenda Digital.
                            </Typography>
                        </React.Fragment>
                        )}
                        {CPinfStatus==='7' && (
                            <React.Fragment>
                                <Typography className="ModalH1txt">
                                    Se canceló la solicitud de cambio de Presentador de tu cliente {CPinfClientName} debido a que el Presentador seleccionado ya no es parte de Thermomix® México.
                                </Typography>
                                <Typography className="ModalH2txt">
                                    Seguirás recibiendo sus recomendaciones en tu Agenda Digital.
                                </Typography>
                                <Typography className="ModalH2txt">
                                    No es necesario que realices ninguna acción.
                                </Typography>
                            </React.Fragment>
                        )}
                    </DialogContent>
                            <DialogActions>
                                {CPinfStatus!=='6' && (
                                <React.Fragment>
                                    <Button className="MuiBadge-colorSecondary editContactBtn" onClick={handleVerCambioPresentador} color="inherit">
                                        <img src="/assets/img/icons/user_white_23.png" alt="Editar"
                                            style={{width: "15px", margin: "5px", marginRight: "12px"}}/>
                                            Ver contacto
                                    </Button>
                                </React.Fragment>
                                )}
                            </DialogActions>
                </Dialog>
        </div>
    )

}